import React, { Component } from 'react'
import styled from 'styled-components'
import jump from 'jump.js'
import { debounce } from 'lodash'
import ease from '../../styles/ease'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  position: fixed;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  top: 0;
  left: 0;
  pointer-events: none;
`

const Button = styled.div`
  position: relative;
  padding: 20px;
  pointer-events: all;
  opacity: 0;
  transition: 0.7s ${ease('outQuad')};
`

export default class ScrollToTop extends Component {
  static propTypes = {}

  constructor(props) {
    super(props)

    this.onScroll = debounce(this.onScroll, 25, {
      maxWait: 100,
      trailing: true
    }).bind(this)
  }

  _show = false

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  goUp = () =>
    jump('.header', {
      duration: 1000
    })

  onScroll() {
    const top =
      window.pageYOffset ||
      document.documentElement.scrollTop ||
      document.body.scrollTop ||
      0
    if (top > 150 && !this._show) {
      this._show = true
      this.btn.style.opacity = 1
      this.btn.style.cursor = 'pointer'
    } else if (top < 150 && this._show) {
      this._show = false
      this.btn.style.opacity = 0
      this.btn.style.cursor = 'auto'
    }
  }

  render() {
    return (
      <Container>
        <Button onClick={this.goUp} ref={i => (this.btn = i)}>
          <svg width="16" height="10" viewBox="0 0 16 10">
            <polyline
              fill="none"
              stroke="#979797"
              strokeLinecap="square"
              strokeWidth="2"
              points="16.24 0 10 5.76 16.24 12"
              transform="matrix(0 1 1 0 2.12 -8.12)"
            />
          </svg>
        </Button>
      </Container>
    )
  }
}
