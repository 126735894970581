import { css } from 'styled-components'

export const fullscreen = css`
    top: 0;
    left:0;
    width:100%;
    height: 100%;
`

export const fullscreenContainer = css`
    position: fixed;
    ${fullscreen}
`

export const fullscreenElement = css`
    position: absolute;
    ${fullscreen}
`

export const centerX = css`
  position: relative;
  left: 50%;
  transform: translateX(-50%);
`

export const centerY = css`
  position: relative;
  top: 50%;
  transform: translateY(-50%);
`

export const centerXY = css`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const centerReset = css`
  transform: translate(0, 0);
`

// Utils
export const test = (color = 'red') => {
  return css`outline: 1px solid ${color} !important`
}

// example limitProp('width', '100vw', '400px', '200px', '50vw')
export const limitProp = (
  cssProp,
  fromSize,
  fromScreenSize,
  toSize,
  toScreenSize
) => {
  const value = getConstrainedViewport(
    fromSize,
    fromScreenSize,
    toSize,
    toScreenSize
  )

  return css`
    ${cssProp}: ${value};

    @media (max-width: ${fromScreenSize}) {
      ${cssProp}: ${fromSize};
    }

    @media (min-width: ${toScreenSize}) {
      ${cssProp}: ${toSize};
    }
  `
}

// http://madebymike.com.au/writing/precise-control-responsive-typography/
function getConstrainedViewport(
  fromSize,
  fromScreenSize,
  toSize,
  toScreenSize
) {
  const screenSizeDifference =
    parseInt(toScreenSize, 10) - parseInt(fromScreenSize, 10)
  const sizeDifference = parseInt(toSize, 10) - parseInt(fromSize, 10)
  const w = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0
  )
  return (
    parseInt(fromSize, 10) +
    sizeDifference * ((w - parseInt(fromScreenSize, 10)) / screenSizeDifference)
  )
}
