import { JSON_LOADED, JSON_LOADING, PAUSE_LOADING } from '../constants/actions'

import { sortBy, values } from 'lodash'

const initialState = {
  pauseLoading: false,
  allImages: null,
  currentSet: [],
  data: false,
  loaded: false,
  loading: false,
  sets: []
}

export default function (state = initialState, action) {
  switch (action.type) {
    case PAUSE_LOADING:
      return {
        ...state,
        pauseLoading: action.paused
      }
    case JSON_LOADING:
      return {
        ...state,
        loading: true
      }
    case JSON_LOADED:
      return {
        ...state,
        currentSet: sortBy(values(action.json.all), 'index'),
        loaded: true,
        loading: false,
        allImages: action.json.all
      }
    default:
      return state
  }
}
