import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
    fill: PropTypes.bool,
    label: PropTypes.string,
    test: PropTypes.bool
  }

  state = {
    hasError: false
  }

  componentDidCatch(error, info) {
    const { label } = this.props
    this.setState({ hasError: true })

    console.warn('Error:', label, error, info)
  }

  render() {
    const { children, fill, label, test } = this.props
    return test || this.state.hasError ? (
      <ErrorDisplay fill={fill}>
        {!!label && (
          <Text center red size="xsmall">
            Section: {label}
          </Text>
        )}
        <Text center>Something went wrong.</Text>
      </ErrorDisplay>
    ) : (
      children
    )
  }
}

export default ErrorBoundary

const Text = styled.div`
  color: red;
`

const ErrorDisplay = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${({ fill }) => (fill ? '100vw' : '100%')};
  height: 100%;
  min-height: 300px;
  text-align: center;
`
