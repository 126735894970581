import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Loader from './Loader'
import { compose, withState, withHandlers } from 'recompose'
import { isNull } from 'lodash'

class DelayedLoader extends Component {
  static propTypes = {
    color: PropTypes.string,
    show: PropTypes.bool,
    showLoader: PropTypes.func,
    time: PropTypes.number
  }

  static defaultProps = {
    time: 2000
  }

  _delay = null

  componentDidMount() {
    this._delay = setTimeout(this.props.showLoader, this.props.time)
  }

  componentWillUnmount() {
    this.reset()
  }

  reset = () => {
    if (!isNull(this._delay)) {
      clearTimeout(this._delay)
      this._delay = null
    }
  }

  render() {
    return this.props.show ? <Loader color={this.props.color} /> : null
  }
}

export default compose(
  withState('show', 'setShow', false),
  withHandlers({ showLoader: props => () => props.setShow(true) })
)(DelayedLoader)
