import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const pulse = keyframes`
  0% {
      opacity: 1;
      stroke-opacity: 1;
  }

  5% {
      opacity: 1;
      stroke-opacity: 1;
  }

  100% {
      opacity: 0;
      stroke-opacity: 0;
  }
`

const SPEED = 500
const SPEED_2 = SPEED / 4 * -1
const SPEED_3 = SPEED / 4 * -2
const SPEED_4 = SPEED / 4 * -3

const Path = styled.path`
  animation: ${pulse} ${SPEED}ms ease-out infinite;
  opacity: 0;

  &:nth-child(2) {
    animation-delay: ${SPEED_2}ms;
  }

  &:nth-child(3) {
    animation-delay: ${SPEED_3}ms;
  }

  &:nth-child(4) {
    animation-delay: ${SPEED_4}ms;
  }
`
const Loader = ({ style, backgroundColor, color, hide }) =>
  <svg
    width="50px"
    height="50px"
    viewBox="0 0 100 100"
    style={{ opacity: hide ? 0 : 1 }}
  >
    <g fill={color} stroke={color}>
      <Path
        d="M0,50 L50,100 L0,100 L0,50 Z"
        transform="translate(25, 75) scale(-1, -1) translate(-25, -75)"
      />
      <Path
        d="M50,50 L100,100 L50,100 L50,50 Z"
        transform="translate(75, 75) scale(1, -1) translate(-75, -75)"
      />
      <Path d="M50,0 L100,50 L50,50 L50,0 Z" />
      <Path
        d="M0,0 L50,50 L0,50 L0,0 Z"
        transform="translate(25, 25) scale(-1, 1) translate(-25, -25)"
      />
    </g>
    <g fill={color} fillOpacity="0.1">
      <Path d="M50,0 L100,50 L50,50 L50,0 Z M50,0 L0,50 L50,50 L50,0 Z M50,100 L100,50 L50,50 L50,100 Z M50,100 L0,50 L50,50 L50,100 Z" />
    </g>
  </svg>

Loader.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  hide: PropTypes.bool,
  style: PropTypes.object
}

export default Loader
