import React from 'react'
import { func } from 'prop-types'
import styled from 'styled-components'
import ease from '../../styles/ease'
import { GREY, HOVER_GREY } from '../../constants/style'

const CloseBtn = styled.div`
  position: relative;
  cursor: pointer;
  pointer-events: auto;
  cursor: pointer;
  padding: 10px 15px;

  svg {
    height: 16px;
    width: 16px;
  }

  path {
    stroke: ${GREY};
    transition: 0.3s stroke ${ease('inQuad')};
  }

  @media (hover: hover) {
    &:hover {
      path {
        stroke: ${HOVER_GREY};
        transition: 0.2s stroke ${ease('outQuad')};
      }
    }
  }
`

const GridButton = ({ onClick }) => {
  return (
    <CloseBtn onClick={onClick}>
      <svg viewBox="0 0 16 16">
        <g
          fill="none"
          fillRule="evenodd"
          stroke="#979797"
          strokeLinecap="square"
          strokeWidth={2}
          transform="translate(3 3)"
        >
          <path d="M11.9090909,0 L0,12" />
          <path d="M11.9090909,0 L0,12" transform="matrix(1 0 0 -1 0 12)" />
        </g>
      </svg>
    </CloseBtn>
  )
}

GridButton.propTypes = {
  onClick: func.isRequired
}

export default GridButton
