import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { IS_MOBILE } from '../../constants/device'
import { IMAGES } from '../../constants/routes'

import ErrorBoundary from '../ErrorBoundary'

import styled, { css } from 'styled-components'
import { compose } from 'recompose'
import imageLoader from '../../hoc/imageLoader'
import { injectActions, injectCurrentImageId, injectImagesById, injectPauseLoading } from '../../hoc/injects'
import { isNull } from 'lodash'
import { getRootPath } from '../../utils/pathUtil'
import { withRouter } from 'react-router'

const formatPosition = (str) => str.replace('_', ' ')

const Cell = styled.div`
  ${({ fit, loaded, isLoaded, position }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-width: 100%;
    top: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;

    img {
      object-fit: ${fit || 'contain'};
      object-position: ${position ? formatPosition(position) : 'center'};
      max-width: calc(100% - 100px);
      max-height: calc(100% - 80px);
      filter: ${loaded ? 'none' : '3px'};
      transform: ${isLoaded ? 'none' : 'scale(1)'};

      @media (min-width: 1400px) or (orientation: landscape) {
        object-fit: contain;
      }

      @media (orientation: landscape) {
        object-fit: contain;
      }
    }
  `};
`
const CellMobile = styled(Cell)`
  img {
    max-width: calc(100% - 40px);
  }
`

class ImageCell extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    cancelLoad: PropTypes.func.isRequired,
    currentId: PropTypes.string,
    currentIndex: PropTypes.number,
    height: PropTypes.number.isRequired,
    item: PropTypes.object.isRequired,
    imageList: PropTypes.array,
    img: PropTypes.object,
    loaded: PropTypes.bool.isRequired,
    location: PropTypes.object,
    loadImage: PropTypes.func.isRequired,
    pauseLoading: PropTypes.bool,
  }

  _index = null
  _mounted = true
  _loaded = false

  componentDidMount() {
    const { currentId, item } = this.props

    this.setHeight()

    const { load } = this.getIndex()
    if (load || currentId === item.id) {
      this.loadHighRes()
    }
  }

  componentWillUnmount() {
    this._mounted = false
  }

  componentDidUpdate(prevProps, prevState) {
    const { actions, currentId, img, item, loaded } = this.props
    if (prevProps !== this.props.height) this.setHeight()

    if (isNull(this.props.img)) {
      const { load } = this.getIndex()
      if (load || currentId === item.id) {
        this.loadHighRes()
      } else if (!load) {
        this.props.cancelLoad()
      }
    }

    if (!prevProps.loaded && loaded) {
      actions.imageLoaded(img)
    }
  }

  getIndex = () => {
    const { currentIndex, imageList, item } = this.props
    const len = imageList.length
    const index = imageList.findIndex((i) => i === item.id)
    const dif = Math.abs(index - currentIndex)
    // Calculate the distance from end of the array
    const difLoop = index < len / 2 ? dif : Math.abs(dif - len)
    // Get the smallest distance
    const distanceFromIndex = Math.min(dif, difLoop)
    // Load nearest neighbor, increase to load more
    const load = distanceFromIndex <= 3
    return { index, distanceFromIndex, load }
  }

  loadHighRes = () => {
    const { location, loaded, loadImage, pauseLoading, img, item } = this.props
    const isImages = getRootPath(location.pathname) === IMAGES

    if ((loaded || !isNull(img) || pauseLoading, !isImages)) return
    loadImage(item.large.src)
  }

  setHeight = () => {
    const { height } = this.props
    this.container.style.height = this.img.style.height = `${height}px`
  }

  render() {
    const { item, loaded } = this.props
    const ImageCell = IS_MOBILE ? CellMobile : Cell

    return (
      <ErrorBoundary label={`Image cell: ${item.id}`}>
        <ImageCell
          ref={(ref) => (this.container = ref)}
          className="img-cell"
          loaded={loaded}
          fit={item.fit}
          position={item.scale}
          style={{ backgroundColor: item.bgColor }}
        >
          <img
            ref={(ref) => (this.img = ref)}
            src={loaded ? item.large.src : item.small.src}
            alt={item.id}
            width={item.large.width}
            height={item.large.height}
          />
        </ImageCell>
      </ErrorBoundary>
    )
  }
}

export default compose(
  injectActions,
  injectCurrentImageId,
  injectImagesById,
  injectPauseLoading,
  imageLoader,
  withRouter,
)(ImageCell)
