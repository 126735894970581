// Object fit polyfill

class Fitie {
  constructor() {
    if (/MSIE|Trident/.test(navigator.userAgent)) {
      let all = document.querySelectorAll('img,video')
      let index = -1

      while (all[++index])
        this.fit(all[index])
    }
  }

  fit(node) {
    // restrict to valid object-fit value
    let objectFit = node.currentStyle['object-fit']

    if (!objectFit || !/^(contain|cover|fill)$/.test(objectFit)) return

    // prepare container styles
    let outerWidth = node.clientWidth
    let outerHeight = node.clientHeight
    let outerRatio = outerWidth / outerHeight

    let name = node.nodeName.toLowerCase()

    let setCSS = node.runtimeStyle
    let getCSS = node.currentStyle

    let addEventListener = node.addEventListener || node.attachEvent
    let removeEventListener = node.removeEventListener || node.detachEvent
    let on = node.addEventListener ? '' : 'on'
    let img = name === 'img'
    let type = img ? 'load' : 'loadedmetadata'

    addEventListener.call(node, on + type, onload)

    if (node.complete) onload()

    function onload() {
      removeEventListener.call(node, on + type, onload)

      // prepare container styles
      let imgCSS = {
        boxSizing: 'content-box',
        display: 'inline-block',
        overflow: 'hidden'
      }

      'backgroundColor backgroundImage borderColor borderStyle borderWidth bottom fontSize lineHeight height left opacity margin position right top visibility width'.replace(
        /\w+/g,
        function (key) {
          imgCSS[key] = getCSS[key]
        }
      )

      // prepare image styles
      setCSS.border = setCSS.margin = setCSS.padding = 0
      setCSS.display = 'block'
      setCSS.height = setCSS.width = 'auto'
      setCSS.opacity = 1

      let innerWidth = node.videoWidth || node.width
      let innerHeight = node.videoHeight || node.height
      let innerRatio = innerWidth / innerHeight

      // style container
      let imgx = document.createElement('object-fit')

      imgx.appendChild(node.parentNode.replaceChild(imgx, node))

      // eslint-disable-next-line
      for (let key in imgCSS)
        imgx.runtimeStyle[key] = imgCSS[key]

      // style image
      let newSize

      if (objectFit === 'fill') {
        if (img) {
          setCSS.width = outerWidth
          setCSS.height = outerHeight
        } else {
          setCSS['-ms-transform-origin'] = '0% 0%'
          setCSS['-ms-transform'] =
            'scale(' +
            outerWidth / innerWidth +
            ',' +
            outerHeight / innerHeight +
            ')'
        }
      } else if (
        innerRatio < outerRatio
          ? objectFit === 'contain'
          : objectFit === 'cover'
      ) {
        newSize = outerHeight * innerRatio

        setCSS.width = Math.round(newSize) + 'px'
        setCSS.height = outerHeight + 'px'
        setCSS.marginLeft = Math.round((outerWidth - newSize) / 2) + 'px'
      } else {
        newSize = outerWidth / innerRatio

        setCSS.width = outerWidth + 'px'
        setCSS.height = Math.round(newSize) + 'px'
        setCSS.marginTop = Math.round((outerHeight - newSize) / 2) + 'px'
      }
    }
  }
}

export default Fitie
