// Fonts
export const BACKUP =
  'system, -apple-system, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif'
export const SEMI_BOLD = `'SharpSansNo1-Semibold', ${BACKUP}`
export const MED = `'SharpSansNo1-Medium', ${BACKUP}`
export const BOLD = `'SharpSansNo1-Bold', ${BACKUP}`
export const BOOK = `'SharpSansNo1', ${BACKUP}`
export const LIGHT = `'SharpSansNo1-Light', ${BACKUP}`

// Colors
export const COLOR_WHITE = 'colorWhite'
export const COLOR_BLACK = 'colorBlack'
export const GREY = '#727272'
export const GREY_ALT = 'rgb(114, 114, 114)'
export const LIGHT_GREY = '#929292'
export const HOVER_GREY = '#333'
