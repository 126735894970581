import React, { Component } from 'react'
import PropTypes from 'prop-types'
import anime from 'animejs'
import { debounce } from 'lodash'

import { GREY } from '../../constants/style'
import { IS_IOS } from '../../constants/device'

const LINE_STYLE = {
  fill: 'none',
  stroke: GREY,
  strokeMiterlimit: 10,
  strokeWidth: 31
}

const POLY_STYLE = { fill: GREY, opacity: 0 }

class JoshuaDraw extends Component {
  static propTypes = {
    seek: PropTypes.number
  }

  constructor(props) {
    super(props)

    this.onScroll = debounce(this.onScroll, 25, {
      maxWait: 100,
      trailing: true
    }).bind(this)
  }

  componentDidMount() {
    const easing = 'easeInOutSine'

    const lineDrawing = {
      targets: [this.j, this.o, this.s, this.h1, this.h2, this.u],
      strokeDashoffset: [anime.setDashoffset, 0],
      easing,
      duration: 1500,
      delay: (el, i) => i * 250
    }

    const offset = 500

    const a1 = {
      targets: this.a1,
      opacity: 1,
      easing,
      duration: 100,
      offset
    }

    const a2 = {
      targets: this.a2,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing,
      duration: 1500,
      offset
    }

    const a3 = {
      targets: this.a3,
      opacity: 1,
      easing,
      duration: 100,
      offset: offset + 1500
    }

    const a4 = {
      targets: this.a4,
      strokeDashoffset: [anime.setDashoffset, 0],
      easing,
      duration: 1500,
      offset
    }

    this.tl = anime.timeline({
      autoplay: false,
      direction: 'alternate'
      // loop: true
    })

    this.tl.add(lineDrawing)
    this.tl.add(a1)
    this.tl.add(a2)
    this.tl.add(a3)
    this.tl.add(a4)
    this.tl.seek(0)

    window.addEventListener('scroll', this.onScroll, false)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  onScroll() {
    const y = document.documentElement.scrollTop || document.body.scrollTop
    const height = document.documentElement.clientHeight || window.innerHeight
    const offset = IS_IOS ? 70 : 0
    const scrollHeight =
      (document.documentElement.scrollHeight || document.body.scrollHeight) -
      offset

    const rangeHeight = 100

    const yElement =
      y < scrollHeight - height - rangeHeight
        ? 0
        : Math.abs(scrollHeight - height - rangeHeight - y)

    const percent = (yElement / rangeHeight) * 3000

    this.tl.seek(percent)
  }

  render() {
    return (
      <svg
        ref={i => (this.svg = i)}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1921.52 1091.56"
      >
        <g>
          <path
            ref={i => (this.j = i)}
            d="M293.08.5V459.21c0,89.89-51.91,151.5-141.79,151.5-68.36,0-114.78-31.23-136.73-81.87"
            style={LINE_STYLE}
          />
        </g>
        <path
          ref={i => (this.o = i)}
          d="M442.08,242.84c-80.37,0-155.53,31.47-211.64,88.63C174.65,388.3,143.92,464,143.92,544.56s30.73,156.26,86.52,213.09c56.11,57.15,131.27,88.63,211.64,88.63s155.74-31.48,212.08-88.63c56.08-56.89,87-132.57,87-213.09s-30.88-156.2-87-213.09C597.83,274.32,522.51,242.84,442.08,242.84Z"
          style={LINE_STYLE}
        />
        <g>
          <path
            ref={i => (this.s = i)}
            d="M739.24,544.53c-34.6-44.31-86.93-71.74-159.94-71.74-89.46,0-161.2,54.44-161.2,137.57,0,96.64,83.13,123.22,167.53,150.23s168.8,57.81,168.8,157.83c0,96.22-79.76,158.67-178.93,158.67-76.38,0-141.79-34.6-181-84.4"
            style={LINE_STYLE}
          />
        </g>
        <g id="h">
          <path
            ref={i => (this.h1 = i)}
            d="M857.86,156.5V772.62"
            style={LINE_STYLE}
          />
          <path
            ref={i => (this.h2 = i)}
            d="M1261.09,464.14h-388"
            style={LINE_STYLE}
          />
        </g>
        <g id="u">
          <path
            ref={i => (this.u = i)}
            d="M1664.71,480.07V898.16c0,99.59-86.22,178.93-194.46,178.93s-193.54-79.34-193.54-178.93V156.5"
            style={LINE_STYLE}
          />
        </g>
        <g id="a">
          <polygon
            ref={i => (this.a1 = i)}
            style={POLY_STYLE}
            points="1412.78 882.18 1406.6 898 1440.36 898 1446.51 882.18 1412.78 882.18"
          />
          <path
            ref={i => (this.a2 = i)}
            d="M1426,891.62,1655.06,299h16.46l230.1,593.6"
            style={LINE_STYLE}
          />
          <polygon
            ref={i => (this.a3 = i)}
            style={POLY_STYLE}
            points="1881.47 882.18 1887.68 898 1920.6 898 1914.42 882.18 1881.47 882.18"
          />
          <path
            ref={i => (this.a4 = i)}
            d="M1817.66,694.22H1509.43"
            style={LINE_STYLE}
          />
        </g>
      </svg>
    )
  }
}

export default JoshuaDraw
