import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { debounce, findIndex, get, throttle, isUndefined } from 'lodash'
import { compose, withState } from 'recompose'
import { vh } from '../../utils/viewport'

import ErrorBoundary from '../ErrorBoundary'
import FlickityReact from './FlickityReact'
import keyCodes from '../../constants/keyCodes'
import { injectActions, injectImagesDicitonary } from '../../hoc/injects'
import { getRootPath } from '../../utils/pathUtil'

import { IMAGES } from '../../constants/routes'
import { IS_MOBILE } from '../../constants/device'

import './ImagesSet.css'

class ImagesSet extends Component {
  constructor(props) {
    super(props)

    this.onKeyDown = throttle(this.onKeyDown, 250).bind(this)
    this.onResize = debounce(this.onResize, 25, {
      maxWait: 100,
      trailing: true
    }).bind(this)
  }

  static propTypes = {
    allImages: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    currentSet: PropTypes.array.isRequired,
    filter: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    height: PropTypes.number.isRequired,
    id: PropTypes.string,
    index: PropTypes.number.isRequired,
    match: PropTypes.object.isRequired,
    setHeight: PropTypes.func.isRequired,
    setIndex: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.checkForCurrentImage()

    document.body.addEventListener('keydown', this.onKeyDown, false)
    window.addEventListener('resize', this.onResize, false)

    this.onResize()
  }

  componentDidUpdate(prevProps, prevState) {
    //const { match } = this.props
    //const prevFilter = get(prevProps.match, 'params.filter')
    //const filter = get(match, 'params.filter')
    const rootPath = getRootPath(this.props.location.pathname)

    this.checkForCurrentImage(true)

    if (rootPath === IMAGES) {
      // Update url for selected filter
      //  if (prevFilter.filter !== filter) {
      // const firstImage = first(currentSet)
      // if (firstImage) history.push(`/images/${filter}/${firstImage.id}`)
      //}

      document.body.addEventListener('keydown', this.onKeyDown, false)
    } else {
      document.body.removeEventListener('keydown', this.onKeyDown)
    }

    this.setSiteColor()
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown)
    window.removeEventListener('resize', this.onResize)
  }

  getPositionFromId = () => {
    const { currentSet, id } = this.props
    const index = findIndex(currentSet, item => item.id === id)
    return index
  }

  checkForCurrentImage = (checkCurrentId = false) => {
    const { currentSet, id, setIndex } = this.props

    let flickityId = null
    if (checkCurrentId) {
      const index = get(this.flkty, 'flkty.selectedIndex')
      if (!isUndefined(index)) flickityId = get(currentSet[index], 'id')
    }

    let index = 0

    // Get position from url
    if (id !== flickityId) {
      const pos = this.getPositionFromId(id)
      if (pos !== -1) {
        index = pos
        if (this.props.index !== index) setIndex(index)
      }
      this.setSiteColor()
    }
  }

  setSiteColor = () => {
    const { currentSet, actions } = this.props
    const index = get(this.flkty, 'index') || -1

    if (!currentSet.length || index === -1) return
    const img = currentSet[index]
    if (img) {
      const color = img.color && img.color === '' ? '#fff' : img.color
      const bgColor = img.bgColor && img.bgColor === '' ? '#fff' : img.bgColor
      actions.setColor(color, bgColor)
    } else {
      console.warn('## Image not found.', this.flkty, img)
    }
  }

  onSelected = () => {
    const { allImages, location, history, match, currentSet } = this.props
    const rootPath = getRootPath(location.pathname)

    if (rootPath !== IMAGES) return

    const filter = get(match, 'params.filter') || 'all'
    const currentId = get(match, 'params.image')
    const index = get(this.flkty, 'flkty.selectedIndex')

    if (isUndefined(index)) return
    const img = currentSet[index]
    const id = get(img, 'id')
    if (currentId !== id) {
      history.push(`/${IMAGES}/${filter}/${id}`)

      const title = get(allImages, `${id}.name`)
      if (title) document.title = `Joshua Stearns Images | ${title}`
    }
    this.setSiteColor()
  }

  onKeyDown(e) {
    if (!this.flkty) {
      return console.warn('Error, this.flkty:', this.flkty)
    }
    switch (e.keyCode) {
      case keyCodes.LEFT:
        this.flkty.prev()
        break
      case keyCodes.RIGHT:
        this.flkty.next()
        break
      default:
    }
  }

  onResize() {
    const height = vh()
    if (this.props.height !== height) {
      this.props.setHeight(height)
    }
  }

  render() {
    const { currentSet, height, index, filter } = this.props

    return (
      <ErrorBoundary>
        <FlickityReact
          ref={i => (this.flkty = i)}
          height={height}
          images={currentSet}
          index={index}
          isMobile={IS_MOBILE}
          key={filter}
          onIndexChange={this.onSelected}
        />
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = state => ({
  currentSet: state.images.currentSet,
  filter: state.site.filter
})

export default compose(
  withState('index', 'setIndex', -1),
  withState('height', 'setHeight', vh()),
  connect(mapStateToProps),
  injectActions,
  injectImagesDicitonary,
  withRouter
)(ImagesSet)
