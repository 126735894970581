import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import anime from 'animejs'
import ease from '../../styles/ease'

const MIN = 70
const MAX = 150
const X_LEN = 160.59808349609375

const SVG = styled.svg`
  width: 10vw;
  height: 10vw;
  max-width: ${MAX}px;
  max-height: ${MAX}px;
  min-width: ${MIN}px;
  min-height: ${MIN}px;
  transition: 0.3s background-color ${ease('inQuad')};

  polygon,
  path,
  line {
    transition: 0.3s fill ${ease('inQuad')};
  }

  line {
    stroke-dasharray: ${X_LEN};
    stroke-dashoffset: ${X_LEN};
  }

  @media (max-width: 900px) {
    use {
      stroke-width: 5;
    }
  }

  @media (max-width: 800px) {
    use {
      stroke-width: 6;
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: #000;
      transition: 0.2s background-color ${ease('outQuad')};

      polygon,
      path {
        fill: #fff;
        transition: 0.2s fill ${ease('outQuad')};
      }

      line {
        stroke: #fff;
        transition: 0.2s fill ${ease('outQuad')};
      }
    }
  }
`

class InfoButton extends Component {
  static propTypes = {
    isInfo: PropTypes.bool.isRequired
  }

  componentDidMount() {
    if (this.props.isInfo) {
      this.animateX()
    } else {
      this.animateInfo()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!prevProps.isInfo && this.props.isInfo) {
      this.animateX()
    }

    if (prevProps.isInfo && !this.props.isInfo) {
      this.animateInfo()
    }
  }

  animateInfo = () => {
    const targets = [this.letterI, this.letterN, this.letterF, this.letterO]
    targets.forEach(item => (item.style.opacity = 0))

    anime({
      opacity: 1,
      easing: 'easeOutExpo',
      delay: (target, i) => 250 + i * 100,
      duration: 500,
      targets
    })
  }

  animateX = () => {
    const targets = [this.line1, this.line2]
    // const length = this.line1.getTotalLength()

    anime({
      strokeDashoffset: [X_LEN, 0],
      easing: 'easeOutExpo',
      delay: (target, i) => 250 + i * 500,
      duration: 500,
      targets
    })
  }

  render() {
    const { isInfo } = this.props
    return (
      <SVG viewBox="0 0 153 153">
        <g fill="none" fillRule="evenodd">
          <rect
            width={151}
            height={151}
            x={1}
            y={1}
            stroke="#000"
            strokeWidth={2}
          />
          {!isInfo &&
            <g fill="#000" transform="translate(33 33)">
              <polygon
                ref={i => (this.letterI = i)}
                points="3 15.43 4.328 16.749 16.675 4.319 15.347 3"
                transform="rotate(-45 9.838 9.875)"
              />
              <polygon
                ref={i => (this.letterN = i)}
                points="20 32.43 21.328 33.749 31.662 23.346 28.718 41.09 30.131 42.494 42.478 30.064 41.15 28.744 30.9 39.063 33.828 21.471 32.347 20"
                transform="rotate(-45 31.24 31.247)"
              />
              <polygon
                ref={i => (this.letterF = i)}
                points="43 57.43 44.328 58.749 49.893 53.147 55.597 58.813 56.848 57.553 51.144 51.887 55.424 47.579 61.128 53.245 62.379 51.985 55.347 45"
                transform="rotate(-45 52.69 51.907)"
              />
              <path
                ref={i => (this.letterO = i)}
                d="M67.6559999,81.3838507 C64.1313642,77.8827127 64.1122937,72.1806355 67.6134318,68.6559999 C71.1145699,65.1313642 76.816647,65.1122937 80.3412827,68.6134318 C83.8659183,72.1145699 83.8849888,77.816647 80.3838507,81.3412827 C76.8827127,84.8659183 71.1806355,84.8849888 67.6559999,81.3838507 Z M68.9076144,80.1238361 C71.7681883,82.9653394 76.2309105,82.797678 79.0216727,79.9881858 C81.8124349,77.1786937 81.9502419,72.7149498 79.0896681,69.8734465 C76.2290943,67.0319431 71.766372,67.1996045 68.9756098,70.0090967 C66.1848476,72.8185889 66.0470406,77.2823327 68.9076144,80.1238361 Z"
                transform="rotate(-45 73.999 74.999)"
              />
            </g>}
          {!!isInfo &&
            <g
              id="x"
              strokeWidth={2}
              strokeMiterlimit={10}
              stroke="#000"
              fill="none"
            >
              <line
                ref={i => (this.line1 = i)}
                id="x2"
                x1="19.72"
                y1="19.72"
                x2="133.28"
                y2="133.28"
              />
              <line
                ref={i => (this.line2 = i)}
                id="x1"
                x1="133.28"
                y1="19.72"
                x2="19.72"
                y2="133.28"
              />
            </g>}
        </g>
      </SVG>
    )
  }
}

export default InfoButton
