import bowser from 'bowser'
import { get } from 'lodash'

const browser = bowser.getParser(window.navigator.userAgent)

const browserInfo = browser.getBrowser()

export const supportsDecodeImage = browser.satisfies({
  windows: {},

  macos: {
    safari: '>11'
  },

  mobile: {
    safari: '>11'
  },

  tablet: {},

  desktop: {
    chrome: '>64'
  }
})

export const IS_MOBILE =
  get(browser, 'parsedResult.platform.type') !== 'desktop'

export const IS_SAFARI = get(browserInfo, 'name') === 'Safari'

export const IS_IOS = IS_MOBILE && IS_SAFARI
