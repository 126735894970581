import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import anime from 'animejs'

const ASPECT_RATIO = 257 / 373

export const imageSize = css`
  height: calc(100% - 4vw);
  width: auto;
  min-height: 100px;
  min-width: ${100 * ASPECT_RATIO}px;
  margin: 2vw 0;
  margin-right: 6vw;
  object-fit: scale-down;
  max-width: 30vw;

  @media (max-width: 800px) {
    max-height: 150px;
    max-width: ${150 * ASPECT_RATIO}px;
  }

  @media (min-height: 750px) {
    max-width: ${50 * ASPECT_RATIO}vh;
  }
`

const Shape = styled.svg`
  ${imageSize};
`

const LINE_STYLE = {
  fill: 'none',
  stroke: '#000',
  strokeMiterlimit: 10,
  strokeWidth: 5
}

const POLY_STYLE = { opacity: 0 }

export default class Joshua extends Component {
  componentDidMount() {
    [this.j, this.o, this.s, this.h, this.u, this.a].forEach(
      item => (item.style.opacity = 0)
    )

    const relativeOffset = anime.timeline()
    const settings = {
      easing: 'easeOutQuad',
      duration: 400,
      opacity: 1
    }
    const offset = 300

    relativeOffset
      .add({
        targets: this.j,
        strokeDashoffset: [anime.setDashoffset, 0],
        delay: 100,
        ...settings
      })
      .add({
        targets: this.o,
        ...settings,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        targets: this.s,
        ...settings,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        targets: this.h,
        ...settings,
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.h1,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.h2,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.h3,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        targets: this.u,
        ...settings,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.a1,
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.a,
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.a2,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.a3,
        strokeDashoffset: [anime.setDashoffset, 0],
        offset: `-=${offset}`
      })
      .add({
        ...settings,
        targets: this.a4
      })
  }

  render() {
    return (
      <Shape viewBox="0 0 257 373">
        <path
          ref={i => (this.j = i)}
          d="M52.59,0V54.12C52.59,64.73,46.46,72,35.86,72c-8.07,0-13.54-3.68-16.13-9.66"
          style={LINE_STYLE}
        />
        <path
          ref={i => (this.o = i)}
          d="M38.11,102a34.14,34.14,0,0,0-24.55,10.28,35.46,35.46,0,0,0,0,49.44,34.52,34.52,0,0,0,49.15,0,35.33,35.33,0,0,0,0-49.44A34.29,34.29,0,0,0,38.11,102Z"
          style={LINE_STYLE}
        />
        <path
          ref={i => (this.s = i)}
          d="M147.94,109.81c-4-5.13-10.07-8.31-18.53-8.31-10.36,0-18.67,6.31-18.67,15.94,0,11.19,9.63,14.27,19.41,17.4s19.55,6.7,19.55,18.28c0,11.15-9.24,18.38-20.73,18.38a26.6,26.6,0,0,1-21-9.78"
          style={LINE_STYLE}
        />
        <g ref={i => (this.h = i)}>
          <path
            ref={i => (this.h1 = i)}
            d="M106,200.06v73"
            style={LINE_STYLE}
          />
          <path
            ref={i => (this.h2 = i)}
            d="M153.1,236.51h-46"
            style={LINE_STYLE}
          />
          <path ref={i => (this.h3 = i)} d="M154,200v73" style={LINE_STYLE} />
        </g>
        <path
          ref={i => (this.u = i)}
          d="M203.25,200v49.35a22,22,0,0,0,43.9-.05V200"
          style={LINE_STYLE}
        />
        <g ref={i => (this.a = i)}>
          <polygon
            ref={i => (this.a1 = i)}
            points="195.28 370.38 194.3 372.88 199.63 372.88 200.6 370.38 195.28 370.38"
            style={POLY_STYLE}
          />
          <path
            ref={i => (this.a2 = i)}
            d="M243.86,348.36H207.33"
            style={LINE_STYLE}
          />
          <path
            ref={i => (this.a3 = i)}
            d="M197.43,371.75l27.16-70.24h2l27.27,70.36"
            style={LINE_STYLE}
          />
          <polygon
            ref={i => (this.a4 = i)}
            points="250.6 370.33 251.6 372.88 256.9 372.88 255.91 370.33 250.6 370.33"
            style={POLY_STYLE}
          />
        </g>
      </Shape>
    )
  }
}
