import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ease from '../../styles/ease'
import { HOVER_GREY } from '../../constants/style'

const PrevNextContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: flex-end;
  flex: 1;
  width: 100%;
  color: #000;
  cursor: pointer;
  left: 0;
  pointer-events: none;

  svg {
    width: 12px;
    height: auto;
  }
`

const Btn = styled.div`
  display: flex;
  align-items: flex-end;
  flex: 1;
  height: calc(100% - 80px);
  max-width: 50px;
  opacity: 1;
  transition: 0.2s opacity ${ease('outQuad')};
  pointer-events: auto;
  padding: 5px 0 13px 0;

  svg {
    transform: translate(-15px, -20px);
  }

  &:hover {
    opacity: 1;
    transition: 0.4s opacity ${ease('outQuad')};
  }
`

const PrevNextIcon = styled.div`
  transform: translateY(26px);

  polyline {
    transition: 0.3s stroke ${ease('inQuad')};
  }

  @media (hover: hover) {
    &:hover {
      polyline {
        stroke: ${HOVER_GREY};
        transition: 0.4s stroke ${ease('outQuad')};
      }
    }
  }
`

const Prev = styled(Btn)`
  div {
    transform: scale(-1, 1) translateY(26px) !important;
    filter: FlipH;
  }
`

const Next = styled(Btn)`
  justify-content: flex-end;
`

const Icon = () => (
  <PrevNextIcon>
    <svg viewBox="0 0 10 16">
      <polyline
        fill="none"
        stroke="#979797"
        strokeLinecap="square"
        strokeWidth="2"
        points="6.24 0 0 5.76 6.24 12"
        transform="matrix(-1 0 0 1 8.24 2)"
      />
    </svg>
  </PrevNextIcon>
)

class PrevNext extends Component {
  static propTypes = {
    height: PropTypes.number,
    onPrev: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired
  }

  render() {
    const { height, onPrev, onNext } = this.props
    return (
      <PrevNextContainer style={{ top: height - 40 }}>
        <Prev onClick={onPrev} className="PrevBtn">
          <Icon />
        </Prev>
        <Next onClick={onNext} className="NextBtn">
          <Icon />
        </Next>
      </PrevNextContainer>
    )
  }
}

export default PrevNext
