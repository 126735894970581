import { JSON_LOADED, SET_COLOR, SET_FILTER } from '../constants/actions'

const initialState = {
  bgColor: '#fff',
  color: '#000',
  filter: 'all',
  loaded: false
}

export default function (state = initialState, action) {
  switch (action.type) {
    case JSON_LOADED: {
      //   let images = []
      //   action.json.sets.forEach(set => images = [...images, set.images])
      //   const allImages = flattenDeep(images)
      return {
        ...state,
        loaded: true
        //     data: action.json,
        //     sets: action.json.sets,
        //     allImages,
        //     currentSet: allImages
      }
    }
    case SET_COLOR:
      return {
        ...state,
        color: action.color,
        bgColor: action.bgColor
      }
    case SET_FILTER: {
      const activeIndex =
        state.sets.findIndex(item => item.id === action.filter) || 0
      return {
        ...state,
        filter: action.filter,
        currentSet:
          activeIndex === -1 ? state.allImages : state.sets[activeIndex].images,
        activeIndex
      }
    }
    default:
      return state
  }
}
