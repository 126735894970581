import React, { Component } from 'react'
import { isNull } from 'lodash'

import { supportsDecodeImage } from '../constants/device'

const ImageLoader = ComposedComponent =>
  class extends Component {
    state = {
      loaded: false,
      img: null
    }

    _img = null

    componentWillUnmount() {
      this._cancel()
    }

    _onLoadComplete = () => {
      if (!isNull(this._img)) this.setState({ loaded: true, img: this._img })
    }

    _loadImage = src => {
      this._img = new Image()
      this._img.src = src

      if (supportsDecodeImage) {
        this._img
          .decode()
          .then(this._onLoadComplete)
          .catch(() => {
            console.error(`Could not load/decode image: ${src}`)
          })
      } else {
        this._img.onload = () => this._onLoadComplete()
      }

      this._img.onerror = error => {
        this.cancel()
      }
    }

    _cancel = () => {
      if (!isNull(this._img)) {
        this._img.src = ''
        if (!supportsDecodeImage) {
          this._img.onload = () => {}
        }
        this._img.onerror = () => {}
        this._img = null
      }
    }

    render() {
      return (
        <ComposedComponent
          {...this.state}
          {...this.props}
          loadImage={this._loadImage}
          cancelLoad={this._cancel}
        />
      )
    }
  }

export default ImageLoader
