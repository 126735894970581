window.Promise = global.Promise = require('promise-polyfill')

/*
*
*    Load one:
*
*        load('url.jpg').then(image => {});
*
*    Load Array:
*
*        Promise.all([
*            load('url.jpg'),
*            load('url2.jpg')
*        ]).then(
*           this.onComplete
*        );

        Promise.all(images.map(loadImage)).then(...)
*
*/

const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs='
let loaded = {}

function clearImageVariable(image) {
  // img.src = null; //null will download an image at currentpage/null
  image.src = EMPTY_IMAGE //GC, http://www.fngtps.com/2010/mobile-safari-image-resource-limit-workaround/
}

function promiseMeYoullDownloadThis(src) {
  const image = new Image()
  const promise = new Promise((resolve, reject) => {
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', () => reject(image))
    image.src = src

    setTimeout(() => {
      if (image.complete) {
        resolve(image)
      }
    }, 0)
  })
  promise.image = image
  return promise
}

export default function load(src, preloadOnly = false) {
  if (loaded[src]) {
    return loaded[src]
  }
  const download = promiseMeYoullDownloadThis(src)
  if (preloadOnly) {
    download.then(clearImageVariable)
  } else {
    loaded[src] = download
    return download
  }
}

export function forget(src) {
  if (loaded[src]) {
    loaded[src].then(clearImageVariable)
    delete loaded[src]
  }
}
