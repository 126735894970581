import { createStore, applyMiddleware, compose } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import { routerMiddleware } from 'react-router-redux'
import storage from 'localforage'
import createHistory from 'history/createBrowserHistory'
import createLogger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from '../reducers'

const DEV = process.env.NODE_ENV === 'development'

const whitelist = ['images', 'site']

const persistConfig = {
  key: 'root',
  storage,
  whitelist
}

const reducer = persistReducer(persistConfig, rootReducer)
const history = createHistory()
const historyMiddleware = routerMiddleware(history)

const logger = createLogger({
  collapsed: true,
  predicate: (getState, action) => {
    // These create lots of noise, removing from console
    const actions = []
    const blackList = actions.filter(item => action.type === item)
    return blackList.length === 0
  }
})

const middleware = DEV
  ? [thunk, historyMiddleware, logger]
  : [thunk, historyMiddleware]

const enhancers = [
  applyMiddleware(...middleware),
  window.devToolsExtension ? window.devToolsExtension() : f => f
]

export default function configureStore(initialState) {
  const store = createStore(reducer, initialState, compose(...enhancers))

  persistStore(store)
  // Purge localStorage if needed
  // persistor.purge()

  return { store, history }
}
