import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import styled, { keyframes } from 'styled-components'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { get, capitalize, debounce } from 'lodash'
import { isInViewport } from '../../utils/viewport'

import GridImage from './GridImage'
import Masonry from 'react-masonry-component'
import JoshuaDraw from './JoshuaDraw'
import ScrollToTop from './ScrollToTop'

import { INDEX } from '../../constants/routes'
import { MED, GREY } from '../../constants/style'
import { centerX, limitProp } from '../../styles/mixins'
import ease from '../../styles/ease'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const Container = styled.div`
  position: relative;
  background-color: #000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const GridContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 2s ${ease('outQuad')};
  animation-iteration-count: 1;
`
const MasonryContainer = styled.div`
  ${centerX} ${limitProp(
  'max-width',
  '95vw',
  '320px',
  '90vw',
  '1024px'
)} padding: 20px 0 100px;
  min-height: 20vh;

  &:after {
    content: '';
    display: block;
    clear: both;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 40vh;
  max-height: 400px;

  svg {
    height: 100%;
    width: auto;
    max-width: 90vw;
    object-fit: contain;
    margin: 4vh 0;
  }
`

const Legal = styled.div`
  font-family: ${MED};
  color: ${GREY};
  text-align: center;
  padding: 0 45px 0;
  margin: 20px 0 20px;
  font-size: 11px;
  text-transform: uppercase;
  opacity: 0.6;
  line-height: 20px;

  @media (max-width: 460px) {
    font-size: 9px;
    line-height: 13px;
  }
`
const A = styled.a`
  white-space: nowrap;

  &:link,
  &:visited,
  &:active {
    color: ${GREY};
    transition: color 0.2s ${ease('inQuad')};
  }

  &:hover {
    color: #fff;
    transition: color 0.3s ${ease('outQuad')};
  }
`

class Grid extends Component {
  static propTypes = {
    currentSet: PropTypes.array.isRequired,
    init: PropTypes.bool,
    filter: PropTypes.string.isRequired,
    location: PropTypes.object.isRequired,
    setInit: PropTypes.func
  }

  constructor(props) {
    super(props)

    this.onScroll = debounce(this.onScroll, 25, {
      maxWait: 100,
      trailing: true
    }).bind(this)
  }

  // The viewportFactor defines how much of the appearing item has to be visible in order to trigger the animation
  // if we'd use a value of 0, this would mean that it would add the animation class as soon as the item is in the viewport.
  // If we were to use the value of 1, the animation would only be triggered when we see all of the item in the viewport (100% of it)
  _viewportFactor = 0.5
  _itemsRenderedCount = 0
  _imageList = []

  componentDidMount() {
    window.addEventListener('scroll', this.onScroll, false)
  }

  componentDidUpdate(prevProps) {
    const pathname = get(this.props.location, 'pathname')
    const paths = pathname.split('/').filter(i => i.length !== 0)

    if (!this.props.init && paths.length !== 3) {
      this.props.setInit(true)
    }

    if (!prevProps.init && this.props.init) {
      this.masonry.on('layoutComplete', this.handleLayoutComplete)
    }
  }

  componentWillUnmount() {
    this.masonry.off('layoutComplete', this.handleLayoutComplete)
  }

  handleLayoutComplete = () => this.onScroll()

  addImageToQueue = img => this._imageList.push(img)

  prefix(el, property, value) {
    const propCaps = capitalize(property)
    el.style[`Webkit${propCaps}`] = value
    el.style[`Moz${propCaps}`] = value
    el.style[property] = value
  }

  onScroll() {
    this._imageList.forEach((el, i) => {
      const animate = el.classList.contains('animate')
      if (!animate && isInViewport(el, this._viewportFactor)) {
        this.checkTotalRendered()
        el.classList.add('animate')
      }
    })
  }

  checkTotalRendered = () => {
    this._itemsRenderedCount++
    if (this._itemsRenderedCount === this._imageList.length) {
      window.removeEventListener('scroll', this.onScroll)
      this.masonry.off('layoutComplete', this.handleLayoutComplete)
    }
  }

  onImagesLoaded = img => this.onScroll()

  onImageLoaded = () => this.masonry.layout()

  render() {
    const { currentSet, filter, init } = this.props

    return (
      <Container>
        <GridContainer>
          {!!init && (
            <Fragment>
              <MasonryContainer>
                <Masonry
                  ref={ref => {
                    this.masonry = this.masonry || ref.masonry
                  }}
                  options={{ transitionDuration: 0 }}
                  disableImagesLoaded={false}
                  updateOnEachImageLoad
                  onImagesLoaded={this.onImagesLoaded}
                  key={filter}
                >
                  {currentSet.map((img, i) => (
                    <GridImage
                      addImageToQueue={this.addImageToQueue}
                      key={i}
                      filter={filter}
                      img={img}
                      onSmallLoaded={this.onImageLoaded}
                    />
                  ))}
                </Masonry>
              </MasonryContainer>

              <Footer>
                <JoshuaDraw />
                <Legal>
                  <div>
                    {'Site by '}
                    <A href="https://zero.style">Zero Style</A>.
                  </div>
                  <div>
                    {`© ${new Date().getFullYear()} Joshua Stearns Images, LLC. All rights reserved.`}
                  </div>
                </Legal>
              </Footer>
            </Fragment>
          )}
        </GridContainer>
        <ScrollToTop />
      </Container>
    )
  }
}

const mapStateToProps = state => ({
  currentSet: state.images.currentSet,
  filter: state.site.filter
})

export default compose(
  withRouter,
  withState('init', 'setInit', props => {
    const pathname = get(props.location, 'pathname')
    return !props.init && pathname === INDEX
  }),
  connect(mapStateToProps)
)(Grid)
