import React from 'react'
import { Provider } from 'react-redux'
import { render } from 'react-dom'
import { ConnectedRouter } from 'react-router-redux'

import App from './components/App'
import configureStore from './stores/configureStore'
import './utils/preventRightClickOnImages'

const style = 'background: #222; color: #ecb1ff; padding: 4px;'
console.log('%c Design & Development: Zero Style https://zero.style v1.1', style)

const { store, history } = configureStore()

render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root'),
)
