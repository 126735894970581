import React, { Component } from 'react'
import anime from 'animejs'
import styled from 'styled-components'
import ease from '../../styles/ease'

import DocumentTitle from 'react-document-title'
import Placeholder from './Placeholder'

import { GREY, MED } from '../../constants/style'

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  margin: 1vw 0;
  width: 100%;
`

const LG_SIZE = 3.5
const SM_SIZE = 24
const XS_SIZE = 18
const BigText = styled.div`
  font-family: ${MED};
  font-size: ${LG_SIZE}vw;
  line-height: ${LG_SIZE * 1.4}vw;
  color: #000;
  margin-bottom: 20px;

  @media (max-width: 800px) {
    font-size: ${SM_SIZE}px;
    line-height: ${SM_SIZE * 1.4}px;
    margin-bottom: 15px;
  }

  @media (max-width: 340px) {
    font-size: ${XS_SIZE}px;
    line-height: ${XS_SIZE * 1.4}px;
    margin-bottom: 10px;
  }
`

const LG_LINK_SIZE = 2
const SM_LINK_SIZE = 14
const XS_LINK_SIZE = 12
const LinkText = styled.div`
  font-family: ${MED};
  font-size: ${LG_LINK_SIZE}vw;
  line-height: ${LG_LINK_SIZE * 1.6}vw;
  margin-bottom: 0;
  position: relative;

  a {
    color: #000;
    transition: 0.3s color ${ease('inQuad')};
    margin-left: 35px;

    &:before {
      content: '';
      position: absolute;
      display: inline;
      top: 50%;
      left: 0px;
      width: 20px;
      height: 2px;
      background: #000;

      @media (max-width: 1000px) {
        width: 15px;
        height: 1px;
      }
    }

    @media (max-width: 1000px) {
      margin-left: 30px;
    }
  }

  @media (hover: hover) {
    a:hover {
      color: ${GREY};
      transition: 0.2s color ${ease('outQuad')};
    }
  }

  @media (max-width: 800px) {
    font-size: ${SM_LINK_SIZE}px;
    line-height: ${SM_LINK_SIZE * 1.8}px;
  }

  @media (max-width: 319px) {
    font-size: ${XS_LINK_SIZE}px;
    line-height: ${XS_LINK_SIZE * 2}px;
  }
`

export default class Info extends Component {
  componentDidMount() {
    this.js.style.opacity = 0
    this.phone.style.opacity = 0
    this.email.style.opacity = 0
    this.twitter.style.opacity = 0

    const relativeOffset = anime.timeline()
    const settings = {
      easing: 'easeOutQuad',
      duration: 400,
      opacity: 1
    }
    const offset = 300

    relativeOffset
      .add({
        targets: this.js,
        delay: 100,
        ...settings
      })
      .add({
        targets: this.phone,
        ...settings,
        offset: `-=${offset}`
      })
      .add({
        targets: this.email,
        ...settings,
        offset: `-=${offset}`
      })
      .add({
        targets: this.twitter,
        ...settings,
        offset: `-=${offset}`
      })
  }

  render() {
    return (
      <div>
        <DocumentTitle title="Joshua Stearns Images | Information" />
        <Placeholder />
        <TextContainer>
          <BigText ref={i => (this.js = i)}>JOSHUA STEARNS</BigText>
          <LinkText ref={i => (this.phone = i)}>
            <a href="tel:1-323-638-9406">+1 323/ 638-9406</a>
          </LinkText>
          <LinkText ref={i => (this.email = i)}>
            <a href="mailto:me@joshuastearns.com">me@joshuastearns.com</a>
          </LinkText>
          <LinkText ref={i => (this.twitter = i)}>
            <a href="https://twitter.com/joshua_stearns">@joshua_stearns</a>
          </LinkText>
        </TextContainer>
      </div>
    )
  }
}
