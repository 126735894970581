import { combineReducers } from 'redux'
import { routerReducer as router } from 'react-router-redux'

import images from './imagesReducer'
import site from './siteReducer'
import queue from './queueReducer'

const rootReducer = combineReducers({
  images,
  router,
  site,
  queue
})

export default rootReducer
