import { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

import { MED, BOOK } from './constants/style'
import { FONTS_FACE } from './styles/type'

const style = createGlobalStyle`
${normalize()}
${FONTS_FACE}

*, *:after, *::before {
  box-sizing: border-box;
}

*, img {
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

body, html {
   background-color: #000;
  padding: 0;
  margin: 0;
  font-family: ${BOOK};
  font-display: auto;
  -webkit-text-size-adjust: none; /* Prevent font scaling in landscape */
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4 {
  font-family: ${MED};
}

input {
  outline: none;
  -webkit-user-select: auto;
}

a:link, a:visited, a:active {
  text-decoration: none;
}
`

export default style
