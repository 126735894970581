import actions from '../actions/actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { get, last } from 'lodash'
import { getPathsFromPathname } from '../utils/pathUtil'

export const injectActions = connect(
  null,
  dispatch => ({
    actions: bindActionCreators(actions, dispatch)
  })
)

const imageList = state => state.images.currentSet

export const injectImagesDicitonary = connect(state => ({
  allImages: state.images.allImages
}))

export const injectImages = connect(state => ({
  imageList,
  imageListOpen: state.images.currentSet.filter(img => img.status === 'open')
}))

export const injectAllImages = connect(state => ({
  images: state.images.allImages
}))

export const injectCurrentImageId = connect(state => {
  const pathname = get(state, 'router.location.pathname')
  const path = getPathsFromPathname(pathname)
  const currentId = last(path)
  const ids = state.images.currentSet.map(item => item.id)
  return {
    currentId,
    currentIndex: ids.findIndex(i => i === currentId)
  }
})

export const injectImagesById = connect(state => ({
  imageList: state.images.currentSet.map(item => item.id)
}))

export const injectPauseLoading = connect(state => ({
  pauseLoading: state.images.pauseLoading
}))
