import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose } from 'recompose'

import ErrorBoundary from './ErrorBoundary'
import Header from './header/Header'
import Images from './images/Images'
import GlobalStyle from '../globalStyle'
import Grid from './home/Grid'
import DelayedLoader from './shared/DelayedLoader'
import Fitie from '../lib/Fitie'
import ServiceWorker from './ServiceWorker'

import ease from '../styles/ease'
import keyCodes from '../constants/keyCodes'
import { get, throttle, first } from 'lodash'
import preventScroll from 'prevent-scroll'
import { injectActions } from '../hoc/injects'
import { getIdFromPath, getPathsFromPathname } from '../utils/pathUtil'
import { request } from 'graphql-request'

import { INDEX, INFO } from '../constants/routes'

class App extends Component {
  static propTypes = {
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    loaded: PropTypes.bool,
  }

  constructor(props) {
    super(props)

    this.onKeyDown = throttle(this.onKeyDown, 250).bind(this)

    // Object fit polyfill
    new Fitie()
  }

  componentDidMount() {
    document.body.addEventListener('keydown', this.onKeyDown, false)

    this.getData()
  }

  componentWillReceiveProps(nextProps) {
    const pathname = get(nextProps.location, 'pathname')
    if (pathname === INDEX || pathname === `/${INFO}`) {
      let title = 'Joshua Stearns Images'
      if (pathname === `/${INFO}`) title += '| Information'
      document.title = title
      preventScroll.off()
    } else {
      preventScroll.on()
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('keydown', this.onKeyDown)
  }

  getData = async () => {
    const { actions } = this.props
    actions.jsonLoading()

    const url = 'https://api-us-west-2.graphcms.com/v2/ckug1aoie8ybs01yzd6j12kjm/master'

    const req = await request(
      url,
      `
      {
        contacts {
          email
          phone
          phoneLabel
        }
        imageSets {
          id
          slug
          title
          imageSet {
            ... on Image {
              id
              title
              slug
              color {
                hex
              }
              bgColor {
                hex
              }
              caption
              crop
              fit
              scale
              slug
              imageSmall {
                id
                size
                height
                width
                url(transformation: {image: {resize: {fit: max, height: 10, width: 10}}})
              }
              image {
                id
                size
                height
                width
                url(transformation: {image: {resize: {fit: max, height: 2000, width: 2000}}})
              }
            }
          }
        }
      }
    `,
    )

    // Normalize data
    const imagesSets = req.imageSets.filter(({ slug }) => slug === 'all')
    const allSet = get(first(imagesSets), 'imageSet')
    const sets = allSet.map(({ color, bgColor, slug, image, imageSmall, crop, fit, scale, ...item }, index) => {
      return {
        ...item,
        index,
        id: slug,
        crop: crop ? crop : 'center',
        fit: fit ? fit : 'contain',
        scale: scale ? scale : 'center',
        color: !!color && color.hex ? color.hex : '#fff',
        bgColor: bgColor.hex,
        large: { ...image, src: image.url },
        small: { ...imageSmall, src: imageSmall.url },
        tags: '',
        video: '',
      }
    })

    const all = {}
    sets.forEach((item) => (all[item.id] = item))
    const contact = first(req.contacts)
    const information = { email: { value: contact.email }, phone: { label: contact.phoneLabel, value: contact.phone } }
    const data = { all, information }
    actions.jsonLoaded(data)
  }

  enableEvents = () => {
    const pathname = get(this.props.location, 'pathname')
    return pathname !== INDEX && pathname !== `/${INFO}`
  }

  onKeyDown(e) {
    if (this.enableEvents()) {
      switch (e.keyCode) {
        case keyCodes.ESC:
        case keyCodes.UP:
        case keyCodes.DOWN:
          return this.props.history.push(INDEX)
        default:
      }
    }
  }

  render() {
    const { location, loaded } = this.props
    const id = getIdFromPath(location.pathname)
    const paths = getPathsFromPathname(location.pathname)

    return (
      <ErrorBoundary>
        <ServiceWorker />
        <div>
          <ErrorBoundary>
            <Header />
          </ErrorBoundary>
          <ErrorBoundary>
            {loaded ? (
              <Grid />
            ) : (
              <LoaderContainer>
                <DelayedLoader color="#fff" />
              </LoaderContainer>
            )}
          </ErrorBoundary>
          <Overlay darken={paths.length === 3} />
          <ErrorBoundary>{!!loaded && <Images id={id} />}</ErrorBoundary>
        </div>
        <GlobalStyle />
      </ErrorBoundary>
    )
  }
}

const LoaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color: #000;
  padding-top: 10vh;
`

const fadeInOverlay = css`
  opacity: 0.5;
  transition: opacity 0.4s ${ease('outQuad')};
  pointer-events: all;
`

const fadeOutOverlay = css`
  opacity: 0;
  transition: opacity 1.4s ${ease('inOutQuad')};
  pointer-events: none;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
  pointer-events: none;
  ${({ darken }) => (darken ? fadeInOverlay : fadeOutOverlay)};
`

const mapStateToProps = (state) => ({
  loaded: state.site.loaded,
})

export default compose(
  withRouter,
  injectActions,
  connect(mapStateToProps),
)(App)
