import { IMAGE_LOADED } from '../constants/actions'

const initialState = {
  img: {}
}

export default function (state = initialState, action) {
  switch (action.type) {
    case IMAGE_LOADED:
      return {
        ...state,
        img: {
          ...state.img,
          [action.img.src]: action.img
        }
      }
    default:
      return state
  }
}
