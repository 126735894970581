import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { INDEX, IMAGES } from '../../constants/routes'

import ErrorBoundary from '../ErrorBoundary'
import ImagesSet from './ImagesSet'
import Options from './Options'

import anime from 'animejs'
import ease from '../../styles/ease'
import styled, { css } from 'styled-components'
import { injectActions } from '../../hoc/injects'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { fullscreen, fullscreenElement } from '../../styles/mixins'
import { first, isNull } from 'lodash'

class Images extends Component {
  static propTypes = {
    actions: PropTypes.object,
    backgroundColor: PropTypes.string,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    id: PropTypes.string,
    init: PropTypes.bool,
    setInit: PropTypes.func.isRequired,
    setVisible: PropTypes.func.isRequired,
    visible: PropTypes.bool,
  }

  _transtionedIn = false

  componentDidMount() {
    this._checkRoute()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this._checkRoute()
    }
  }

  _checkRoute = () => {
    const { actions, init, location, setInit, setVisible, visible } = this.props
    const paths = location.pathname.split('/').filter((i) => i !== '')
    const isImages = paths.length === 3 && first(paths) === IMAGES

    if (visible !== isImages) {
      setVisible(isImages)

      if (isImages) {
        this._reset()
        actions.pauseLoading(isImages)
        this._timeout = setTimeout(actions.pauseLoading, 1000)
      }
    }

    if (isImages) {
      if (!this._transtionedIn) this._in()
      if (!init) setInit(true)
    } else {
      if (!init || this._transtionedIn) this._out()
    }
  }

  _reset = () => {
    if (isNull(this._timeout)) {
      clearTimeout(this._timeout)
      this._timeout = null
    }
  }

  _in = () => {
    anime({
      targets: this._el,
      translateY: 0,
      easing: 'easeOutExpo',
      duration: 1000,
      delay: 400,
    })

    this._transtionedIn = true
  }

  _out = () => {
    anime({
      targets: this._el,
      translateY: '100%',
      easing: 'easeInOutExpo',
      duration: this.props.init ? 1000 : 0,
    })

    this._transtionedIn = false
  }

  _goToIndex = () => this.props.history.push(INDEX)

  render() {
    const { backgroundColor, location, history, id, init, visible } = this.props

    return (
      <ErrorBoundary label="Images">
        <Container ref={(i) => (this._el = i)} style={{ backgroundColor }} visible={visible}>
          {!!init && (
            <ImagesInnerContainer>
              <ImagesSet id={id} location={location} history={history} />
              <Options goToIndex={this._goToIndex} />
            </ImagesInnerContainer>
          )}
        </Container>
      </ErrorBoundary>
    )
  }
}

const mapStateToProps = (state) => ({
  backgroundColor: state.site.bgColor,
})

export default compose(
  connect(mapStateToProps),
  withState('init', 'setInit', false),
  withState('visible', 'setVisible', false),
  withRouter,
  injectActions,
)(Images)

const Container = styled.div`
  ${fullscreenElement};
  ${({ visible }) => css`
    position: fixed;
    min-width: 100%;
    min-height: calc(100vh - env(safe-area-inset-bottom));
    transition: 0.35s background-color ${ease('outQuad')};
    z-index: 9999;
    overflow: hidden;
    box-shadow: 0px -10px 20px -10px rgba(0, 0, 0, 0.2);
    transform: translateY(0);
    opacity: 1;
    pointer-events: auto;
  `};
`

const ImagesInnerContainer = styled.div`
  ${fullscreen};
  position: relative;
  height: 100%;
`
