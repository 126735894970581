import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ease from '../../styles/ease'
import { GREY, HOVER_GREY, SEMI_BOLD } from '../../constants/style'

import CloseButton from './CloseButton'

const Container = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
`

const Top = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
`

const Josh = styled.div`
  color: ${GREY};
  font-family: ${SEMI_BOLD};
  pointer-events: all;
  cursor: pointer;
  text-transform: uppercase;
  transition: 0.3s color ${ease('inQuad')};
  padding: 10px 15px;

  @media (hover: hover) {
    &:hover {
      color: ${HOVER_GREY};
      transition: 0.2s color ${ease('outQuad')};
    }
  }
`

const Options = ({ goToIndex }) => (
  <Container className="NavLow">
    <Top>
      <Josh onClick={goToIndex}>JOSHUA STEARNS</Josh>
      <CloseButton onClick={goToIndex} />
    </Top>
  </Container>
)

Options.propTypes = {
  goToIndex: PropTypes.func.isRequired
}

export default Options
