import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { compose, withState } from 'recompose'
import { isNull } from 'lodash'

const Div = styled.div`
  display: flex;
  justify-content: center;

  top: 0;
  left: 0;
  width: 100%;

  z-index: 9999999;
`

const Message = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 10px 10px;
  max-width: calc(100% - 40px);

  font-size: 13px;
  color: #fff;
  background-color: #000;

  span {
    top: 2px;
    margin-right: 10px;
  }
`

class ServiceWorker extends Component {
  static propTypes = {
    message: PropTypes.string,
    setMessage: PropTypes.func
  }

  componentWillMount() {
    this.registerServiceWorker()
  }

  _timeout = null

  componentWillUnmount() {
    this.reset()
  }

  close = () => {
    this.reset()
    this.props.setMessage(null)
  }

  reset = () => {
    if (this.timeout) {
      clearTimeout(this.timeout)
      this._timeout = null
    }
  }

  registerServiceWorker() {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`
        navigator.serviceWorker
          .register(swUrl)
          .then(registration => {
            registration.onupdatefound = () => {
              const installingWorker = registration.installing
              installingWorker.onstatechange = () => {
                if (installingWorker.state === 'installed') {
                  if (navigator.serviceWorker.controller) {
                    this.props.setMessage(
                      'New content is available. Please refresh.'
                    )
                    this.timeout = setTimeout(this.close, 3000)
                  } else {
                    console.log('Content is cached for offline use.')
                  }
                }
              }
            }
          })
          .catch(error => {
            console.error('Error during service worker registration:', error)
          })
      })
    }
  }

  unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister()
      })
    }
  }

  render() {
    const { message } = this.props
    return isNull(message) ? null : (
      <Div onClick={this.close}>
        <Message>
          <span>{message}</span>
        </Message>
      </Div>
    )
  }
}

export default compose(withState('message', 'setMessage', null))(ServiceWorker)
