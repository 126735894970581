import React from 'react'
import styled from 'styled-components'

import { MED } from '../../constants/style'

const Div = styled.div`
  width: auto;
  min-height: 100px;
  margin: 2vw 0;
  margin-right: 6vw;
  text-transform: uppercase;
  font-family: ${MED};

  @media (max-width: 800px) {
    max-height: 150px;
  }
`

export default function NotFound() {
  return <Div>Page not found</Div>
}
