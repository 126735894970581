import React from 'react'
import styled from 'styled-components'
import logo from './joshua.svg'
import { imageSize } from './Joshua'

export const ASPECT_RATIO = 257 / 373

const Image = styled.img`
  ${imageSize}
`

const Invisible = styled.div`
  visibility: hidden;
`

export default function Placeholder() {
  return (
    <Invisible>
      <Image src={logo} alt="js" />
    </Invisible>
  )
}
