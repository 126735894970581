import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { compose } from 'recompose'
import { get } from 'lodash'
import styled from 'styled-components'
import ease from '../../styles/ease'
import load from '../../utils/imageLoader'
import isInViewport from '../../hoc/isInViewport'

import { IMAGES } from '../../constants/routes'

import './GridImage.css'

const Item = styled.div`
  width: 100%;
  filter: ${(props) => (props.loaded ? 'none' : '3px')};
  transform: ${(props) => (props.isLoaded ? 'none' : 'scale(1)')};
  transition: 0.3s opacity, 0.3s filter;
  cursor: pointer;

  @media (min-width: 320px) {
    width: 50%;
  }

  @media (min-width: 640px) {
    width: 33.333%;
  }

  @media (min-width: 1280px) {
    width: 25%;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    padding: 10px;
    opacity: ${(props) => (props.smallLoaded ? 1 : 0)};
    transition: 0.3s opacity ${ease('outQuad')};
    object-fit: contain;

    &:after {
      position: absolute;
      content: '';
      display: block;
      background-color: rgba(114, 114, 114, 0.3);
      width: calc(100% - 20px);
      height: calc(100% - 20px);
      transform: translate(10px, 10px);
    }
  }

  &:after {
    content: '';
    display: block;
    border-bottom: 1px solid #fff;
    width: calc(100% - 20px);
    transform: translate(10px, -10px);
    opacity: 0;
    transition: 0.2s opacity ${ease('outQuad')};
    z-index: 9999;
  }

  @media (hover: hover) {
    &:hover {
      opacity: 0.8;
      filter: saturate(0.9);
      transition: 0.3s opacity, 0.3s filter;

      &:after {
        opacity: 1;
        transition: 1s opacity ${ease('outQuad')};
      }
    }
  }
`

class GridImage extends Component {
  static propTypes = {
    addImageToQueue: PropTypes.func.isRequired,
    checkIfInView: PropTypes.func.isRequired,
    filter: PropTypes.string,
    history: PropTypes.object.isRequired,
    img: PropTypes.object.isRequired,
    isInView: PropTypes.bool.isRequired,
    onSmallLoaded: PropTypes.func.isRequired,
  }

  state = {
    loaded: false,
    smallLoaded: false,
  }

  _largeStarted = false
  _live = true

  componentDidMount() {
    this.loadSmall()

    this.image.onContextMenu = () => false
  }

  componentDidUpdate(prevProps, prevState) {
    const smallLoaded = !prevState.smallLoaded && this.state.smallLoaded
    const isInView = !prevProps.isInView && this.props.isInView

    if (smallLoaded || isInView) {
      if (!isInView) this.props.checkIfInView()
      this.loadLarge()
    }
  }

  componentWillUnmount() {
    this._live = false
  }

  loadSmall = () => {
    load(this.props.img.small.src).then(() => {
      if (this._live) this.props.onSmallLoaded()
      if (this._live) this.setState({ smallLoaded: true })
    })
  }

  loadLarge = () => {
    if (this._largeStarted || !this.props.isInView || !this.state.smallLoaded) return

    this._largeStarted = true

    this.props.addImageToQueue(this.image)

    load(this.props.img.large.src).then((image) => {
      this.setState({ loaded: true })
    })
  }

  goLarge = () => {
    const { filter, history, img } = this.props

    history.push(`/${IMAGES}/${filter}/${img.id}`)

    const title = get(img, 'name')
    if (title) document.title = `Joshua Stearns Images | ${title}`
  }

  render() {
    const { img, isInView } = this.props
    const { loaded, smallLoaded } = this.state

    return (
      <Item isInView={isInView} loaded={loaded} smallLoaded={smallLoaded} onClick={this.goLarge}>
        <img
          ref={(i) => (this.image = i)}
          src={loaded ? img.large.src : img.small.src}
          alt=""
          width={img.large.width}
          height={img.large.height}
        />
      </Item>
    )
  }
}

export default compose(
  withRouter,
  isInViewport,
)(GridImage)
