import { IMAGE_LOADED, JSON_LOADING, JSON_LOADED, PAUSE_LOADING, SET_COLOR, SET_FILTER } from '../constants/actions'

import { get } from 'lodash'

export function pauseLoading(paused = false) {
  return {
    type: PAUSE_LOADING,
    paused,
  }
}

export function jsonLoaded(json) {
  return {
    type: JSON_LOADED,
    json: {
      ...json,
    },
  }
}

export function jsonLoading() {
  return {
    type: JSON_LOADING,
  }
}

export function setFilter(filter) {
  return { type: SET_FILTER, filter }
}

export function setColor(color, bgColor) {
  return (dispatch, getState) => {
    const state = getState()
    const { site } = state
    if (site.color !== color || site.bgColor !== bgColor) {
      dispatch({ type: SET_COLOR, color, bgColor })
    }
  }
}

export function imageLoaded(img) {
  const valid = get(img, 'src') ? true : false
  if (valid) {
    return {
      type: IMAGE_LOADED,
      img,
    }
  }
}
