import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Link, Route, Switch } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'
import { get } from 'lodash'
import { fullscreenElement } from '../../styles/mixins'
import { compose, withState } from 'recompose'
import ease from '../../styles/ease'
import jump from 'jump.js'

import Placeholder from './Placeholder'
import InfoButton from './InfoButton'
import Info from './Info'
import Joshua from './Joshua'
import NotFound from '../not-found/NotFound'

import { INDEX, INFO } from '../../constants/routes'
import { MED } from '../../constants/style'

const fadeIn = keyframes`
  from {
    background-color: #000;
  }

  to {
    background-color: #fff;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2vw 6vw;
  background-color: #fff;

  animation-iteration-count: 1;
  z-index: 99999;

  ${css`
    animation: ${fadeIn} 2s ${ease('outQuad')};
  `};

  img:last-child {
    align-self: flex-end;
  }

  @media (max-width: 900px) {
    padding: 2vw 4vw;
  }
`

const RouteContainer = styled.div`
  ${fullscreenElement};
`

const StyledLink = styled(Link)`
  margin: 2vw 0;
`

const Right = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`

const TextContainer = styled.div`
  margin: 2vw 0;
`

const Left = styled.div`
  position: relative;
  width: 100%;
`
const XS_LINK_SIZE = 12

const Text = styled.div`
  text-transform: uppercase;
  font-family: ${MED};
  text-align: right;
  margin-top: 5px;

  @media (max-width: 800px) {
    font-size: 14px;
  }

  @media (max-width: 319px) {
    font-size: ${XS_LINK_SIZE}px;
    line-height: ${XS_LINK_SIZE * 1.4}px;
  }
`

class Header extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    setType: PropTypes.func,
    style: PropTypes.object,
    type: PropTypes.string
  }

  constructor(props) {
    super(props)

    this._check()
  }

  componentDidUpdate(prevProps, prevState) {
    this._check()
  }

  _check = () => {
    const { location, setType, type } = this.props
    const { pathname } = location
    if ((pathname === `/${INFO}` || pathname === INDEX) && type !== pathname) {
      setType(pathname)
    }
  }

  _goToTop = () =>
    jump('.header', {
      duration: 250
    })

  render() {
    const { location, style, type } = this.props
    const pathname = get(location, 'pathname')
    const notFound = pathname !== `/${INFO}` || pathname !== `/${INDEX}`
    const isInfo = pathname === `/${INFO}` && notFound

    return (
      <Wrapper style={style} className="header">
        <Left>
          <Placeholder />
          <RouteContainer>
            {type === INDEX && <Joshua />}
            {type === `/${INFO}` && <Info />}
            <Switch>
              <Route component={NotFound} />
            </Switch>
          </RouteContainer>
        </Left>
        <Right>
          <StyledLink to={isInfo ? INDEX : INFO} onClick={this._goToTop}>
            <InfoButton isInfo={isInfo} />
          </StyledLink>
          <TextContainer>
            <Text>Photographer</Text>
            <Text>Kailua, Hawai'i</Text>
          </TextContainer>
        </Right>
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  withState('type', 'setType', INDEX)
)(Header)
