import offset from 'document-offset'

export const vw = () =>
  Math.max(document.documentElement.clientWidth, window.innerWidth || 0)

export const vh = () =>
  Math.max(document.documentElement.clientHeight, window.innerHeight || 0)

export const scrollY = () =>
  window.pageYOffset || document.documentElement.scrollTop

export const isInViewport = (el, viewportFactor = 0) => {
  // if 0, the element is considered in the viewport as soon as it enters.
  // if 1, the element is considered in the viewport only when it's fully inside
  // value in percentage (1 >= viewportFactor >= 0)

  if (!el) {
    console.error('Element not found', el)
    return false
  }

  const elH = el.offsetHeight
  const scrolled = scrollY()
  const viewed = scrolled + vh()
  const { top } = offset(el)
  const elBottom = top + elH

  return (
    top + elH * viewportFactor <= viewed &&
    elBottom - elH * viewportFactor >= scrolled
  )
}

export default { vw, vh, scrollY }
