import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import Flickity from 'flickity-imagesloaded'
import { fullscreen, fullscreenElement } from '../../styles/mixins'
import ease from '../../styles/ease'

import { IS_MOBILE } from '../../constants/device'

import ImageCell from './ImageCell'
import PrevNext from './PrevNext'
import DelayedLoader from '../shared/DelayedLoader'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

const ImgSet = styled.div`
  ${fullscreenElement};
`

const Fullscreen = styled.div`
  ${fullscreen} animation: ${fadeIn} 0.5s ${ease('outQuad')};
  animation-iteration-count: 1;
`

const LoaderContainer = styled.div`
  ${fullscreen} display: flex;
  justify-content: center;
  align-items: center;
  animation: ${fadeIn} 0.5s ${ease('outQuad')};
  animation-iteration-count: 1;
`

class FlickityReact extends Component {
  static propTypes = {
    height: PropTypes.number.isRequired,
    index: PropTypes.number,
    images: PropTypes.array,
    onIndexChange: PropTypes.func
  }

  _flickityInit = false

  componentDidMount() {
    if (this.props.index !== -1) this.initFlickity()
  }

  componentDidUpdate(prevProps) {
    const { index, images } = this.props
    const indexChanged = prevProps.index !== index
    const imageChanged = prevProps.images.length !== images.length

    if (!this._flickityInit && index !== -1) this.initFlickity()

    if (this._flickityInit && (indexChanged || imageChanged)) {
      this.flkty.selectCell(index, true, true)
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const indexChanged = nextProps.index !== this.props.index
    const heightChanged = nextProps.height !== this.props.height
    const imageChanged = nextProps.images.length && !this.props.images.length
    return indexChanged || heightChanged || imageChanged
  }

  componentWillUnmount() {
    if (this._flickityInit) {
      this.flkty.off('cellSelect', this.onSelected)
      this.flkty.destroy()
      this.flkty = null
    }
  }

  initFlickity = () => {
    if (this._flickityInit) return
    this._flickityInit = true

    const { index } = this.props

    const options = {
      cellSelector: '.img-cell',
      cellAlign: 'center',
      contain: true,
      draggable: true,
      initialIndex: index,
      pageDots: false,
      prevNextButtons: false,
      wrapAround: true,
      imagesLoaded: true,
      arrowShape: 'M 0,50 L 60,00 L 50,30 L 80,30 L 80,70 L 50,70 L 60,100 Z'
    }

    this.flkty = new Flickity(this.imgSet, options)
    this.flkty.on('settle', this.onSelected)
  }

  get index() {
    return this._flickityInit ? this.flkty.selectedIndex : this.props.index
  }

  onSelected = () => this.props.onIndexChange()

  prev = () => {
    this.flkty.previous()
    this.props.onIndexChange(this.flkty.selectedIndex)
  }

  next = () => {
    this.flkty.next()
    this.props.onIndexChange(this.flkty.selectedIndex)
  }

  render() {
    const { height, index, images } = this.props

    return index === -1 ? (
      <LoaderContainer>
        <DelayedLoader />
      </LoaderContainer>
    ) : (
      <Fullscreen className="FS">
        <ImgSet ref={ref => (this.imgSet = ref)}>
          {images.map((item, i) => (
            <ImageCell
              key={i}
              height={height}
              item={item}
              isMobile={IS_MOBILE}
            />
          ))}
        </ImgSet>
        <PrevNext
          height={height}
          ref={ref => (this.PrevNext = ref)}
          isMobile={IS_MOBILE}
          onPrev={this.prev}
          onNext={this.next}
        />
      </Fullscreen>
    )
  }
}

export default FlickityReact
