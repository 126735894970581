import React, { Component } from 'react'
import ReactDOM from 'react-dom'

const IsInViewport = ComposedComponent =>
  class extends Component {
    state = {
      isInView: false
    }

    componentDidMount() {
      window.addEventListener('scroll', this.viewportChangeSize, false)
      window.addEventListener('resize', this.viewportChangeSize, false)
    }

    componentDidUpdate(prevProps, prevState) {
      if (!prevState.isInView && this.state.isInView) {
        this.cancel()
      }
    }

    componentWillUnmount() {
      this.cancel()
    }

    cancel = () => {
      window.removeEventListener('scroll', this.viewportChangeSize)
      window.removeEventListener('resize', this.viewportChangeSize)
      if (this.raf) cancelAnimationFrame(this.raf)
    }

    viewportChangeSize = () => {
      this.raf = requestAnimationFrame(this.checkIfInView)
    }

    checkIfInView = () => {
      const innerHeight = Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
      )
      const rect = ReactDOM.findDOMNode(this.rect).getBoundingClientRect()
      const isInView = rect.top - innerHeight < 0
      if (isInView !== this.state.isInView) this.setState({ isInView })
    }

    render() {
      return (
        <ComposedComponent
          ref={i => (this.rect = i)}
          {...this.state}
          {...this.props}
          checkIfInView={this.checkIfInView}
        />
      )
    }
  }

export default IsInViewport
